<template>
	<div class="ysm-order-four">

		<div align="left" class="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px"
				style="vertical-align: middle;">
			<div align="left">
				<h3>上传X光片</h3>
				<span style="font-size: 0.9375rem;line-height: 1.875rem;">请上传一个口腔全景片OPG,如果有的话,再上传一个头颅侧位片。</span>
				<div  style="font-size: 0.9375rem;line-height: 1.875rem;">您可以在<a href="./xg.pdf" target="_blank"
						style="color: #af9f73;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div>
				<div>
					<el-form :model="ruleForm" ref="ruleForm">
						<el-form-item prop="quanjingDate" label="口腔全景片OPG" required>
							<el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.quanjingDate"
								style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>
						</el-form-item>
						<el-form-item prop="ceweiDate" label="侧位片">
							<el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.ceweiDate"
								style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>
						</el-form-item>
					</el-form>
				</div>
				<div style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">注意</span>：图像需近30天内</div>
				<div style="word-break:break-all;font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">有效格式</span>：.png、.jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff</div>
				<div style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">最小文件大小</span>：100KB</div>
			</div>

		</div>



	<div class="right">
		<!-- 全景 -->
		<div style="position: relative;left: -15.25rem;">全景片<span style="color: red;">*</span></div>
		<el-upload class="upload-demo" drag action='' ref="upload" :http-request=changeFile multiple
			accept=".jpg,.jpeg,.dcm,.bmp,.tif" >
			<img v-show="qjVisiable" class="qj" :src="qjPicUrl" style="width: 100%; height: 100%;" />
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			<div class="el-upload__tip" slot="tip"></div>
		</el-upload>
		<!-- 请上传头颅侧位片 -->
		<div style="position: relative;left: -14.25rem;">头颅侧位片</div>
		<el-upload class="upload-demo1" drag action='' multiple accept=".jpg,.png,.jpeg,.dcm,.bmp,.tif"
			:http-request="changeFile1">
			<img v-show="cwVisiable" class="cw":src="cwPicUrl" style="width: 100%; height: 100%;" />
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			<div class="el-upload__tip" slot="tip"></div>
		</el-upload>
		<span style="position: relative;left: -14.25rem;">*为必填项</span>
		<div style="text-align: right;">
			<el-button @click="goOrderTwo" class="back" type="button">返回</el-button>
			<el-button @click="nextStepKS" class="nextStep" type="button">下一步</el-button>
		</div>
		
	</div>
	
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				ruleForm: {
					quanjingDate: '',
					ceweiDate: '',
					oid: '0'
				},
				qjPicUrl:'',
				cwPicUrl:'',
				cwVisiable:false,
				qjVisiable:false
			}
		},
		methods: {
			// beforeAvatarUpload(file) {
			// 	console.log('file', file)
			// 	let types = ['image/jpeg', 'image/jpg', 'image/png'];
			// 	const isImage = types.includes(file.type);
			// 	if (!isImage) {
			// 		this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			// 	} else {
			// 		//this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			// 	}
			// 	const isLtSize = file.size / 1024 / 1024 < 5;
			// 	if (!isLtSize) {
			// 		this.$message.error('上传图片大小不能超过 1MB!');
			// 	}
			// },
			//上传全景图
			changeFile(file) {
				console.log('file', file) 
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'quanjing')
				console.log(formData.get('type'));
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						
						if (res.data.code == 200) {
							this.$message.success("上传成功");
							this.qjVisiable=true;
							//上传成功,图片回显
							this.qjPicUrl=res.data.data.picUrl;
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//侧位图
			changeFile1(file) {
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'cewei')
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						
						if (res.data.code == 200) {
							this.$message.success("上传成功");
							this.cwVisiable=true;
							//上传成功,图片回显
							this.cwPicUrl=res.data.data.picUrl;
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//下一步
			nextStepKS() {
				//上传两个日期
				let result1;
				let result2;
				let formData1 = new FormData()
				formData1.append('date', this.ruleForm.quanjingDate) // 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type', 'qjDate')

				this.$axios.post("client/order/uploadResourceDate", formData1, {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					result1 = res.data.code;
				}).catch(err => {
					this.$message.error("添加失败");
				});
				//头颅侧位
				let formData2 = new FormData()
				console.log(this.ruleForm.ceweiDate);
				formData2.append('date', this.ruleForm.ceweiDate) // 传文件
				formData2.append('oid', this.ruleForm.oid)
				formData2.append('type', 'cwDate')

				this.$axios.post("client/order/uploadResourceDate", formData2, {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					console.log(res);
					result2 = res.data.code;
					this.$store.commit("setYsmStepIndex",5);
					this.$router.push("/ysmmain/ysmorder/ysmorderfive");
				}).catch(err => {
					this.$message.error("添加失败");
				});

			},
			//返回
			goOrderTwo() {
				this.$router.push("/ysmmain/ysmorder/ysmorderthree")
			}

		},
		mounted: function() {
			this.$store.commit("setYsmStepIndex",4);
			//设置默认地址
			sessionStorage.setItem("location", "/ysmmain/ysmorder/ysmorderfour")
			//获取当前添加的订单oid
			//获取oid
			 let oid=sessionStorage.getItem("oid");
			 if(oid==null||oid=="null"||oid==""||oid==undefined){
			 	this.$alert("请填写患者信息","提示");
			 	this.$router.push("/ysmmain/ysmorder/ysmorderone");
			 }else{
			 	this.ruleForm.oid=oid;
			 }
			 document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			 document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")	
			//设置返回路径
			this.$store.commit("setBackLocation","/ysmmain/ysmorder/ysmorderthree");
		},
		created: function() {
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");
			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("/client/order/getOrderInfo", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					console.log(res.data);
					let qjDate = res.data.data.resource.qjDate;
					let cwDate = res.data.data.resource.cwDate;
					let cewei = res.data.data.resource.cewei;
					let quanjing = res.data.data.resource.quanjing;
					//全景日期回显
					if (qjDate != null) {
						this.ruleForm.quanjingDate = qjDate;
					}
					//侧位日期回显
					if (cwDate != null) {
						this.ruleForm.ceweiDate = cwDate;
					}
					//回显全景图片
					if (quanjing != null) {
						this.qjVisiable=true;
						document.getElementsByClassName("qj")[0].setAttribute("src",quanjing);
					}
					//回显侧位图片
					if (cewei != null) {
						this.cwVisiable=true;
						document.getElementsByClassName("cw")[0].setAttribute("src",cewei);
					}
				}).catch(err => {
					console.log(err);
				});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/ysmmain/ysmorder/ysmorderone");
					}
				});
			}
			
		}
	}
</script>

<style scoped="scoped">
	.ysm-order-four {
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}

	.left {
		
	/* 	display: flex;
		margin-right: 12.5rem;
		width: 31.25rem;
		position: relative;
		left: 12.5rem; */
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		/* position: relative;
		left: 4.15rem;
		 width: 35rem; */
		 width: 560px;
	}
	.ysm-order-four .right .el-upload-dragger{
		width: 25rem;
	}

	/* 按钮 */
	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
</style>
